<template>
  <div class="historyRecord">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <source />
      <div class="navTitle">我的收藏</div>
      <!-- <div class="rightBtn" @click="editMode">
        {{ isEdit ? "保存" : "编辑" }}
      </div> -->
    </div>

    <van-tabs
      class="navTabs oepnX"
      swipeable
      animated
      line-width="24"
      line-height="6"
      v-model="activeName"
      background="rgba(0,0,0,0)"
      v-if="navList && navList.length"
      @change="clickTag"
    >
      <van-tab v-for="(item, index) in navList" :key="index">
        <template #title>
          <span
            class="navTabTitle"
            :class="{ navTabTitleActive: activeName == index }"
          >
            {{ item.title }}
          </span>
        </template>
        <div class="historyRecordContent">
          <PullRefresh
            v-if="!isLoading"
            :loading="loading"
            :refreshing="refreshing"
            :finished="finished"
            @onLoad="onLoad"
            @onRefresh="onRefresh"
            :isNoData="isNoData"
            :error="error"
            :is-higeht-max="true"
            :adv-list="advList"
            :hasAdvPagination="true"
            className="historyRecord"
          >
            <!-- 横板 -->
            <div v-if="activeName == 2">
              <div v-if="videoList && videoList.length > 0">
                <TransverselyTwoList
                  :isEdit="isEdit"
                  @del="del"
                  :videoList="videoList"
                >
                </TransverselyTwoList>
              </div>

              <!-- 猜你想看 -->
              <!-- <div v-if="videoHotList && videoHotList.length > 0" class="guess">
                                <div class="guessTitle">
                                    <span>猜你想看</span>
                                </div>
                                <TransverselyTwoList :videoList="videoHotList"></TransverselyTwoList>
                            </div> -->
            </div>
            <!-- 竖版 -->
            <div v-else>
              <div v-if="videoList && videoList.length > 0">
                <VerticleThreeList
                  :isEdit="isEdit"
                  @del="del"
                  :videoList="videoList"
                >
                </VerticleThreeList>
              </div>

              <!-- 猜你想看 -->
              <!-- <div v-if="videoHotList && videoHotList.length > 0" class="guess">
                                <div class="guessTitle">
                                    <span>猜你想看</span>
                                </div>
                                <VerticleThreeList :videoList="videoHotList"></VerticleThreeList>
                            </div> -->
            </div>
          </PullRefresh>
          <Loading v-else />
        </div>
      </van-tab>
    </van-tabs>

    <div class="delDialog">
      <van-dialog v-model="delShow" show-cancel-button @confirm="delCollect">
        <img
          src="@/assets/png/dialogClose.png"
          class="delDialogBtn"
          @click="delShow = false"
        />
        <div class="dialogContent">
          <span>您是否移除该内容？</span>
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import {
  douUcollect,
  queryCollectInfoList,
  queryHotInfoList,
} from "@/api/video";
import PullRefresh from "@/components/PullRefresh";
import Loading from "@/components/Loading";
import VerticleThreeList from "@/components/VideoList/VerticleThreeList";
import TransverselyTwoList from "@/components/VideoList/TransverselyTwoList";
import { queryHistoryVideo, deletePost } from "@/utils";

export default {
  name: "historyRecord",
  components: {
    PullRefresh,
    Loading,
    VerticleThreeList,
    TransverselyTwoList,
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 10,
      activeName: 0,
      topicId: "",
      zone: "",
      titleName: "",
      videoList: [],
      videoHotList: [],
      isLoading: true,
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      advList: [],
      error: false,
      delShow: false,
      delItem: null,
      navList: [
        {
          title: "动漫",
          kind: 0,
        },
        {
          title: "漫画",
          kind: 1,
        },
        {
          title: "角色扮演",
          kind: 2,
        },
        {
          title: "真人",
          kind: 3,
        },
        {
          title: "小说",
          kind: 4,
        },
        {
          title: "写真",
          kind: 5,
        },
        {
          title: "禁播",
          kind: 6,
        },
      ],
      isTypeLoading: 0, //书籍加载，如果加载完，就加载推荐（0：加载书籍数据，1：加载推荐数据）
      isEdit: false,
    };
  },
  mounted() {
    this.getCollectInfoList("refresh");
    // this.getHotInfoList('refresh');
  },
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    /**
     * 我的收藏
     * @param type
     * @returns {Promise<void>}
     */
    async getCollectInfoList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        kind: this.navList[this.activeName].kind,
      };
      // let list = await queryCollectInfoList(this.pageNumber, this.pageSize, this.navList[this.activeName]
      //     .kind) || [];
      let res = await queryCollectInfoList(req);
      const { data } = res;
      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;
      let list = data.data.MediaList;
      if (res && data.code == 200 && data.data.MediaList !== null) {
        if (list && list.length > 0) {
          if (type == "refresh") {
            this.videoList = list;
            console.log(list, this.videoList);
          } else {
            this.videoList = this.videoList.concat(list);
            console.log(list, this.videoList);
          }
        }
        this.videoList = this.uniqueFun(this.videoList, "id");
        if (
          this.pageNumber === 1 &&
          this.videoList &&
          this.videoList.length === 0
        ) {
          this.isNoData = true;
        }
        if (!res.data.data.hasNext) {
          this.finished = true;
        }
      } else {
        this.videoList = [];
        this.finished = true;
      }
      // this.videoList = this.uniqueFun(this.videoList, 'id')
      // if (this.pageNumber == 1 && this.videoList.length == 0) {
      //     this.isNoData = true;
      // }

      // if (list.length < this.pageSize) {
      //   // this.finished = true;
      //   this.isTypeLoading = 1;
      //   this.getHotInfoList("refresh");
      //   return;
      // }
      // this.isLoading = false;

      // if (res && res.code == 200) {
      //     if (res.data.MediaList && res.data.MediaList.length > 0) {
      //         if (type == "refresh") {
      //             this.videoList = res.data.MediaList;
      //         } else {
      //             this.videoList = this.videoList.concat(res.data.MediaList);
      //         }
      //     } else {
      //         this.videoList = [];
      //     }

      //     // if (this.pageNumber === 1 && this.videoList && this.videoList.length === 0) {
      //     //     this.isNoData = true;
      //     // }
      //     // if (!res.data.hasNext || res.data.MediaList.length < req.pageSize) {
      //     //     // this.finished = true;
      //     //     this.isTypeLoading = 1;
      //     //     this.getHotInfoList('refresh');
      //     // }
      // }
    },
    uniqueFun(arr, type) {
      const res = new Map();
      return arr.filter((a) => !res.has(a[type]) && res.set(a[type], 1));
    },
    async getHotInfoList(type) {
      /**
       * 猜你喜欢
       * @type {{pageNumber: number, kind: number, pageSize: number}}
       */
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        kind: this.navList[this.activeName].kind,
      };
      let res = await this.$Api(queryHotInfoList, req);

      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;

      if (res && res.code == 200) {
        if (res.data.MediaList && res.data.MediaList.length > 0) {
          if (type == "refresh") {
            this.videoHotList = res.data.MediaList;
          } else {
            this.videoHotList = this.videoHotList.concat(res.data.MediaList);
          }
        }

        // if (this.pageNumber === 1 && this.videoList && this.videoList.length === 0) {
        //     this.isNoData = true;
        // }
        if (!res.data.hasNext) {
          this.finished = true;
        }
      }
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.isNoData = false;
      this.loading = true;
      this.getCollectInfoList("refresh");
      // this.getHotInfoList('refresh');
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      if (this.isTypeLoading == 0) {
        this.getCollectInfoList();
      } else {
        this.getHotInfoList();
      }
    },
    clickTag(index) {
      this.videoHotList = [];

      if (index != null) {
        this.activeName = index;
      }
      this.pageNumber = 1;
      this.finished = false;
      this.loading = true;
      this.isLoading = true;
      this.isNoData = false;
      this.getCollectInfoList("refresh");
      // this.getHotInfoList('refresh');
    },
    editMode() {
      this.isEdit = !this.isEdit;
    },
    del(item) {
      this.delItem = item;
      this.delShow = true;
    },
    async delCollect() {
      let res = await deletePost(this.delItem.id);
      // let res = await this.$Api(douUcollect, req);
      // console.log(res)
      if (res && res.code == 200) {
        this.videoList.forEach((item, index) => {
          if (item.id == this.delItem.id) {
            this.videoList.splice(index, 1);
          }
        });
        this.$toast("删除成功");
      } else {
        this.$toast("删除失败");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.historyRecord {
  height: 100%;
  color: #000;

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.5px solid #f2f2f2;

    .navArrow {
      width: 17px;
      height: 17px;
      margin-right: 13px;
    }

    .navTitle {
      flex: 2;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }

    .rightBtn {
      width: 30px;
      color: #333333;
      // height: 17px;
    }
  }

  .headBar {
    display: flex;
    height: 44px;
    align-items: center;
    justify-items: auto;
    border-bottom: solid 1px rgb(230, 230, 230);

    .headTitle {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-weight: bold;
      font-size: 18px;
      padding-left: 52px;
      color: rgb(0, 0, 0);
    }

    .headRightBtn {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-right: 12px;
      font-size: 15px;
      color: rgb(113, 111, 134);
    }
  }

  .navTabs {
    .navTabTitle {
      font-size: 14px;
      color: #999999;
      font-weight: 400;
    }

    .navTabTitleActive {
      font-weight: 600;
      color: #333333;
    }

    ///deep/ .van-tabs__nav--complete {
    //    padding-left: 12px;
    //}
    //
    ///deep/ .van-tabs__wrap--scrollable .van-tab{
    //    padding: 0;
    //    padding-right:21px;
    //}
    //
    /deep/ .van-tabs__wrap {
      width: 100%;
      height: 44px;
      margin-bottom: 12px;
      display: flex;
      justify-content: center;
    }

    /deep/ .van-tabs__line {
      width: 18px !important;
      height: 2px !important;
      border-radius: 1px !important;
      background: #fbd13d !important;
      bottom: 18px !important;
    }

    /deep/ .van-tab {
      padding: 0;
      // width: 40px;
      margin-right: 18px;
      margin-left: 5px;
    }

    .historyRecordContent {
      height: calc(100vh - 100px);
      padding: 0 12px 0 12px;
      overflow-y: auto;

      .guess {
        .guessTitle {
          color: #333333;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }

  .content {
    //width: 100%;
    height: calc(100% - 44px);
    padding: 0 12px;
  }

  .delDialog {
    /deep/ .van-dialog {
      background: rgb(255, 255, 255);
    }

    .delDialogBtn {
      width: 10.6px;
      height: 10.6px;
      position: absolute;
      right: 12px;
      top: 12px;
    }

    /deep/ .van-button--default {
      background: rgb(255, 255, 255);
    }

    /deep/ .van-dialog__cancel {
      color: rgb(153, 153, 153);
    }

    /deep/ .van-hairline--left {
      color: #333333;
    }

    /deep/ [class*="van-hairline"]::after {
      border: 1px solid rgb(233, 233, 233);
    }

    .dialogContent {
      font-size: 18px;
      color: rgb(0, 0, 0);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 18px 70px;
    }
  }
}
</style>
